<template>
	<div class="container">
		<LoginWrap v-show="showLogin" v-on:close-login="closeLogin()" />
		<div class="relative">
			<img src="../assets/support/support_bg.png" class="support-bg" />
			<div class="flex-row align-end space-between mine-info" v-if="isLogin">
				<div class="flex-column">
					<div class="user-name">{{user.name}}</div>
					<div class="support-num">您已支持 {{productSize}} 个项目</div>
				</div>
				<img @click="navTp()" src="../assets/support/hello_icon.png" class="hello-icon" />
			</div>
			<div class="flex-row align-center loginout-wrap" v-if="isLogin" @click="loginout()">
				<img src="../assets/loginout2.png" class="loginout-img" />
				<div class="loginout-text">退出登录</div>
			</div>
		</div>
		<div class="login-guide flex-column align-center" v-if="!isLogin" @click="login()">
			<img src="../assets/support/login_icon.png" class="login-guide-img" />
			<div class="login-guide-con">请登录以查看您支持的众筹项目</div>
		</div>

		<div v-else>
			<div class="empty flex-column align-center" v-if="isEmpty">
				<img src="../assets/support/empty.png" class="empty-img" />
				<div class="empty-con">您暂无支持的众筹项目</div>
			</div>
			<div v-else>
				<div class="tab-sec flex-row align-center">
					<div class="tab-item flex-column align-center" @click.stop="changeTab(1)">
						<div class="tab-item-name">进行中({{startData.num}})</div>
						<div class="tab-select-border" v-if="tab==1"></div>
					</div>
					<div class="tab-item flex-column align-center " @click.stop="changeTab(2)">
						<div class="tab-item-name">已结束({{endData.num}})</div>
						<div class="tab-select-border" v-if="tab==2"></div>
					</div>
				</div>
				<div v-if="tab ==1">
					<van-list v-model="startData.loading" @load="getProjectStart" :finished="startData.finished"
						finished-text="没有更多了" :error.sync="startData.error" error-text="请求失败，点击重新加载">
						<div @click.stop="navShop(index)" class="good-list flex-row align-center space-between flex-wrap">
							<div class="good-item" v-for="(item,index) in startData.list" :key="index">
								<div class="good-top relative">
									<img :src="item.listViewPic" class="good-item-img" />
									<div class="goods-nums2">x {{item.count}}</div>
									<img src="../assets/support/finishi_icon.png" class="finish-icon" v-if="tab==2" />
								</div>
								<div class="good-bot">
									<div class="good-item-name">{{item.name}}</div>
									<div class="flex-row align-center space-between good-item-progress-sec">
										<div class="good-item-progress-bg">
											<div class="good-item-progress"
												:style="'width:'+(item.progress>100?100:item.progress)+'%'"></div>
										</div>
										<div class="good-item-progress-num">{{item.progress}}%</div>
									</div>
									<div class="flex-row align-center space-between good-item-support-sec">
										<div class="flex-row align-center">
											<img src="../assets/support/money_icon.png" class="good-item-money-icon" />
											<div class="good-item-support-title">我已支持</div>
										</div>
										<div class="good-item-support-num">￥{{item.blindBoxBuyAmt}}</div>
									</div>
									<div class="flex-row align-center space-between good-item-time-sec" v-if="tab==1">
										<div class="flex-row align-center">
											<img src="../assets/support/time_icon.png" class="good-item-money-icon" />
											<div class="good-item-support-title">剩余时间</div>
										</div>
										<div class="good-item-time-num">{{item.surplusDay}}天</div>
									</div>
								</div>
							</div>
						</div>
					</van-list>
				</div>

				<div v-else>
					<van-list v-model="endData.loading" @load="getProjectEnd" :finished="endData.finished"
						finished-text="没有更多了" :error.sync="endData.error" error-text="请求失败，点击重新加载">
						<div class="good-list flex-row align-center space-between flex-wrap">
							<div @click.stop="navShop(index)" class="good-item-finish" v-for="(item,index) in endData.list" :key="index">
								<div class="good-top relative">
									<img :src="item.listViewPic" class="good-item-img" />
									<img src="../assets/support/finishi_icon.png" class="finish-icon" />
									<div class="goods-nums2">x {{item.count}}</div>
								</div>
								<div class="good-bot">
									<div class="good-item-name">{{item.name}}</div>
									<div class="flex-row align-center space-between good-item-progress-sec">
										<div class="good-item-progress-bg">
											<div class="good-item-progress"
												:style="'width:'+(item.progress>100?100:item.progress)+'%'"></div>
										</div>
										<div class="good-item-progress-num">{{item.progress}}%</div>
									</div>
									<div class="flex-row align-center space-between good-item-support-sec">
										<div class="flex-row align-center">
											<img src="../assets/support/money_icon.png" class="good-item-money-icon" />
											<div class="good-item-support-title">我已支持</div>
										</div>
										<div class="good-item-support-num">￥{{item.blindBoxBuyAmt}}</div>
									</div>
									<!-- <div class="flex-row align-center space-between good-item-time-sec" v-if="tab==1">
										<div class="flex-row align-center">
											<img src="../assets/support/time_icon.png" class="good-item-money-icon" />
											<div class="good-item-support-title">剩余时间</div>
										</div>
										<div class="good-item-time-num">23天</div>
									</div> -->
								</div>
							</div>
						</div>
					</van-list>
				</div>
			</div>
		</div>
		<div class="bot-tab-sec flex-row align-center">
			<div class="bot-tab-item flex-column align-center justify-center " @click.stop="navHome()">
				<img src="../assets/tabbar/home_tab.png" class="bot-tab-item-icon" />
				<div class="bot-tab-item-name">众筹项目</div>
			</div>
			<div class="bot-tab-item flex-column align-center justify-center bot-tab-item-active">
				<img src="../assets/tabbar/support_tab_selected.png" class="bot-tab-item-icon" />
				<div class="bot-tab-item-name">我支持的</div>
			</div>
		</div>

		<div class="icp flex-row align-center space-around">
			<div @click.stop="navBa()">沪ICP备17003041号-4</div>
			<div class="flex-row align-center">
				<!-- <img src="../assets/beiab.png" class="beiab-img"> -->
				<div @click.stop="navBa2()">沪公网安备 31011402009626号</div>
			</div>
		</div>
	</div>
</template>

<script>
	import config from '../config/config.js';
	import util from '../config/util.js';
	import Vue from 'vue';
	import Vant from 'vant';
	import 'vant/lib/index.css';
	import LoginWrap from '@/components/Login.vue';
	Vue.use(Vant);
	export default {
		data() {
			return {
				tab: 1, //1进行中 2已结束
				isEmpty: false,
				user: {},
				productSize: '',
				startData: {
					loading: false,
					error: false,
					finish: false,
					pageNum: 1,
					pageSize: 10,
					list: [],
					num: ''
				},
				endData: {
					loading: false,
					error: false,
					finish: false,
					pageNum: 1,
					pageSize: 10,
					list: [],
					num: ''
				},
				showLogin: false
			}
		},
		computed: {
			isLogin() {
				return this.$store.state.user.token ? true : false
			}
		},
		components: {
			LoginWrap
		},
		methods: {
			navShop(){
				window.location.href = config.shopUrl
			},
			navTp() {
				window.location.href = config.tpUrl
			},
			loginout() {
				const that = this;
				that.$dialog.confirm({
						title: '退出登录',
						message: '确认要退出登录？',
					})
					.then(() => {
						console.log('退出登录')
						that.$toast.loading({
							message: '退出中...',
							forbidClick: true,
							duration: 0,
						});
						let params = new FormData()
						that.axios.post(config.requestUrl + '/front/logout', params, {
							headers: {
								'Content-Type': 'multipart/form-data',
								'token': that.$store.state.user.token
							}
						}).then(response => {
							if (response.data.code == 0) {
								localStorage.removeItem("user");
								setTimeout(() => {
									that.$router.push('/')
								}, 1200)
								that.$toast.success('退出成功')
							} else {
								let msg = response.data.msg;
								const str = "未获取到用户信息";
								const str2 = "登录已失效"
								if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
									localStorage.removeItem("user");
									that.$store.commit("clearUser");
									that.$store.commit("clearCode");
									setTimeout(() => {
										that.$router.push('/')
									}, 1200)
								}
								Toast(response.data.msg);
							}
						}, response => {
							that.$toast('获取失败，请稍后重试');
						})
					})
					.catch(() => {
						console.log('取消退出登录')
						// on cancel
					});
			},
			navBa2() {
				window.location.href = "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011402009626"
			},
			navBa() {
				window.location.href = "https://beian.miit.gov.cn/#/Integrated/index"
			},
			login() {
				this.showLogin = true;
			},
			closeLogin() {
				this.showLogin = false;
				if (this.$store.state.user.token) {
					this.getUserInfo();
					this.getProjectEnd();
				}
			},
			navHome() {
				this.$router.replace('/')
			},
			changeTab(tab) {
				this.startData = {
					loading: false,
					error: false,
					finish: false,
					pageNum: 1,
					pageSize: 10,
					list: [],
					num: this.startData.num
				}
				this.endData = {
					loading: false,
					error: false,
					finish: false,
					pageNum: 1,
					pageSize: 10,
					list: [],
					num: this.endData.num
				}
				this.tab = tab;

			},
			getProjectStart() {
				const that = this;
				that.startData.loading = true;
				let params = new FormData()
				params.append('pageNum', that.startData.pageNum);
				params.append('pageSize', that.startData.pageSize);
				params.append('status', 1);
				that.axios.post(config.requestUrl + '/front/web3/getProjectByStatus', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.$toast.clear();
						let newList = response.data.obj.pageInfo.list
						for (let i in newList) {
							let progress = 0;
							if (newList[i].soldOutAmount > 0 && newList[i].quantity > 0 && newList[i].price >
								0) {
								progress = util.accDi(newList[i].soldOutAmount, util.accMul(newList[i]
									.quantity,
									newList[i].price)) * 100
							}
							progress = progress.toFixed(2);
							newList[i].progress = progress;
							newList[i].surplusDay = util.surplusDay(newList[i].startTime, newList[i].endTime);
							newList[i].count = util.accDi(newList[i]
								.blindBoxBuyAmt, newList[i].price)
						}
						let list = that.startData.list;
						let finished = false;
						if (response.data.obj.pageInfo.isLastPage) finished = true;
						list = list.concat(newList);
						that.startData.list = list;
						that.startData.pageNum = that.pageNum + 1;
						that.startData.finished = finished;
						that.startData.num = response.data.obj.quantity

					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							setTimeout(() => {
								that.login()
							}, 1200)
						}
						that.$toast(response.data.msg);
						that.startData.error = true;
					}
					that.startData.loading = false;
				}, response => {
					Toast('获取失败，请稍后重试');
					that.startData.loading = false;
					that.startData.error = true;
				})
			},
			getProjectEnd() {
				const that = this;
				let params = new FormData()
				params.append('pageNum', that.endData.pageNum);
				params.append('pageSize', that.endData.pageSize);
				params.append('status', 2);
				that.axios.post(config.requestUrl + '/front/web3/getProjectByStatus', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.$toast.clear();
						let newList = response.data.obj.pageInfo.list
						for (let i in newList) {
							let progress = 0;
							if (newList[i].soldOutAmount > 0 && newList[i].quantity > 0 && newList[i].price >
								0) {
								progress = util.accDi(newList[i].soldOutAmount, util.accMul(newList[i]
									.quantity,
									newList[i].price)) * 100
							}
							progress = progress.toFixed(2);
							newList[i].progress = progress;
							newList[i].count = util.accDi(newList[i]
								.blindBoxBuyAmt, newList[i].price)
							// newList[i].surplusDay = util.surplusDay(newList[i].startTime, newList[i].endTime)
						}
						let list = that.endData.list;
						let finished = false;
						if (response.data.obj.pageInfo.isLastPage) finished = true;
						list = list.concat(newList);
						that.endData.list = list;
						that.endData.pageNum = that.pageNum + 1;
						that.endData.finished = finished;
						that.endData.num = response.data.obj.quantity
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							setTimeout(() => {
								that.login()
							}, 1200)
						}
						that.$toast(response.data.msg);
						that.endData.error = true;
					}
					that.endData.loading = false;
				}, response => {
					Toast('获取失败，请稍后重试');
					that.endData.loading = false;
					that.endData.error = true;
				})
			},
			getUserInfo() {
				const that = this
				let params = new FormData()
				that.axios.post(config.requestUrl + '/front/web3/baseInfo', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					that.getBox = 0;
					if (response.data.code == 0) {
						that.user = response.data.obj.userInfo;
						that.productSize = response.data.obj.productSize
						that.$toast.clear()
					} else {
						that.$toast.fail(response.data.msg);
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							setTimeout(() => {
								//登录
							}, 1200)
						}

					}
				}, response => {
					Toast.fail('获取失败，请稍后重试');
				})
			}
		},
		activated() {
			if (this.$store.state.user.token) {
				this.startData = {
					loading: false,
					error: false,
					finish: false,
					pageNum: 1,
					pageSize: 10,
					list: [],
					num: 0
				}
				this.endData = {
					loading: false,
					error: false,
					finish: false,
					pageNum: 1,
					pageSize: 10,
					list: [],
					num: 0
				}
				this.getUserInfo();
				this.getProjectEnd();
			}
		}
	}

</script>

<style scoped="scoped">
	.container {
		padding-bottom: 140px;
		min-height: 100%;
		width: 100%;
		min-height: 100%;
		overflow: visible;

		box-sizing: content-box;
	}

	.support-bg {
		width: 750px;
		height: 274px;
	}

	.mine-info {
		position: absolute;
		top: 50px;
		left: 50px;
		width: 700px;
	}

	.user-name {
		width: 200px;
		height: 50px;
		background: #6236FF;
		border-radius: 24px;
		font-size: 28px;
		font-weight: bold;
		color: #FFFFFF;
		line-height: 50px;
		text-align: center;
	}

	.support-num {
		margin-top: 30px;
		font-size: 28px;
		color: #FFFFFF;
		line-height: 28px;
	}

	.hello-icon {
		width: 189px;
		height: 80px;
		position: fixed;
		bottom: 300px;
		right: 0;
	}

	.tab-sec {
		width: 750px;
		height: 86px;
		background: #FFFFFF;
		box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.08);
	}

	.tab-item {
		height: 86px;
		width: 50%;
		font-size: 28px;
		font-family: VonwaonBitmap-12px, VonwaonBitmap;
		color: #000000;
		line-height: 34px;
		letter-spacing: 2px;
	}

	.tab-item-name {
		margin-top: 30px;
	}

	.tab-select-border {
		width: 150px;
		height: 6px;
		background: linear-gradient(180deg, #9B66FF 0%, #6236FF 100%);
		border-radius: 3px;
		margin-top: 19px;
	}

	/* 项目列表开始 */
	.good-list {
		padding: 30px;
	}

	.good-item {
		width: 340px;
		padding-bottom: 30px;
		/* height: 500px; */
		background: #FFFFFF;
		border-radius: 10px;
		border: 1px solid rgba(0, 0, 0, 0.1);
		margin-top: 10px;
	}

	.good-top {
		width: 340px;
		height: 280px;
		background: #F7F7F7;
		border-radius: 10px 10px 0px 0px;
		text-align: center;
		position: relative;
	}

	.good-bot {
		padding: 0 20px;
	}

	.good-item-img {
		width: 321px;
		height: 181px;
		margin-top: 44px;
	}

	.good-item-name {
		font-size: 26px;
		font-weight: bold;
		color: #000000;
		line-height: 34px;
		margin-top: 12px;
	}

	.good-item-progress-sec {
		margin-top: 10px;
	}

	.good-item-progress-bg {
		width: 250px;
		height: 14px;
		background: #FFFFFF;
		border-radius: 10px;
		border: 1px solid rgba(0, 0, 0, 0.1);
	}

	.good-item-progress {
		width: 170px;
		height: 14px;
		background: linear-gradient(270deg, #FF8D3D 0%, #FFC9AA 100%);
		border-radius: 10px;
	}

	.good-item-progress-num {
		font-size: 18px;
		color: #000000;
		line-height: 25px;
	}

	.good-item-support-sec {
		margin-top: 17px;
	}

	.good-item-money-icon {
		width: 17px;
		height: 19px;
	}

	.good-item-support-title {
		margin-left: 10px;
		font-size: 24px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 24px;
	}

	.good-item-support-num {
		font-size: 32px;
		font-weight: bold;
		color: rgba(0, 0, 0, 0.5);
		line-height: 32px;
		letter-spacing: 1px;
	}

	.good-item-time-num {
		font-size: 24px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 24px;
		letter-spacing: 1px;
	}

	.good-item-time-sec {
		margin-top: 14px;
	}

	.good-item-finish {
		width: 340px;
		/* height: 464px; */
		padding-bottom: 30px;
		background: #FFFFFF;
		border-radius: 10px;
		border: 1px solid rgba(0, 0, 0, 0.1);
		margin-top: 10px;
	}

	.finish-icon {
		width: 110px;
		height: 34px;
		position: absolute;
		top: 0;
		left: 0;
	}

	/* 项目列表结束 */

	/* 空状态开始 */
	.empty {
		margin-top: 309px;
	}

	.empty-img {
		width: 362px;
		height: 198px;
	}

	.empty-con {
		font-size: 28px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 40px;
		margin-top: 50px;
	}

	/* 空状态结束 */

	.login-guide {
		margin-top: 282px;
	}

	.login-guide-img {
		width: 160px;
		height: 50px;
	}

	.login-guide-con {
		margin-top: 40px;
		font-size: 28px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 28px;
	}

	.loginout-wrap {
		position: absolute;
		top: 20px;
		right: 10px;
		z-index: 100
	}

	.loginout-img {
		width: 30px;
		height: 30px;
		margin-right: 10px;
	}

	.loginout-text {
		font-size: 22px;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 30px;
		letter-spacing: 1px;
	}

	.goods-nums2 {
		position: absolute;
		bottom: 0;
		right: 0;
		min-width: 60px;
		height: 60px;
		padding-left: 15px;
		padding-right: 10px;
		background: rgba(0, 0, 0, 0.6);
		border-radius: 50px 0 0 0;
		text-align: center;
		line-height: 60px;
		font-size: 30px;
		color: #FFFFFF;
	}

</style>

